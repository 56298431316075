<template>
  <div class="w-full  doctorSetting" >
    <div class="vx-row">
 
      <feather-icon @click="back()" :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("Setting") }}
      </p>
    </div>
    <div class="vx-col w-full mt-5">
      <vx-card>
        <div class="vx-row w-full">
          <div class="w-full lg:w-2/3">
            <h2 class="m-3" style="font-family: Futura PT">
              {{ $t("EditProfile") }}
            </h2>
          </div>
          <div class="w-full lg:w-1/3">
            <vs-button
              @click="gotoEditProfile()"
              class="mr-2 customizer-btn rounded-lg text-sm"
              style="width: 95% !important"
            >
              <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
            </vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    // vSelect
    //showOnlineAppointmentCom,
  },
  data() {

    return {
      EnglishLan:false,
      doctorDoc: {},
      search:{},
      // showOnlineAppointmentfield: false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/doctor/card1.png")}) `,
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/doctor/card2.png")})`,
      },
      arrowIcon: {
        backgroundImage: `url(${require("@/assets/images/doctor/arrowIcon.svg")})`,
      },
    };
  },

  methods: {
    back() {
      debugger
      this.$router.go(-1);
    },
    gotoEditProfile() {
      this.$router.push({
        name: "DoctorProfileData",
      });
    },
    showOnlineAppointMent() {
      //this.doctorDoc.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      debugger;
      //this.showOnlineAppointmentfield=true;
      this.$router.push({
        name: "onlineAppointment",
        params: { ID: this.$store.state.AppActiveUser.Doctor.ID },
      });
    },
    getYourPaient() {
      debugger;
      var id = this.$store.state.AppActiveUser.Doctor.ID;
      this.$router.push({ name: "PatientReservation", params: { ID: id } });
    },
    addNewData() {
      this.$router.push({ name: "PatientEdit" });
    },
    editData(ID) {
      this.$router.push({ name: "PatientEdit", params: { ID: ID } });
    },

    deleteData() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("patientList/DeletePatient", this.rowDataForDelete.ID)
        .then(() => {
          this.searchData();
          this.$vs.loading.close();
          window.showDeleteSuccess();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$vs.loading.close();
            window.showDeleteFailed(err.response.data.message);
          } else {
            this.$vs.loading.close();
            window.showDeleteFailed("");
          }
        });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      this.$store
        .dispatch("patientList/SearchPatients", this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
   this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // if (!moduleCountry.isRegistered) {
    //   this.$store.registerModule("CountryList", moduleCountry);
    //   moduleCountry.isRegistered = true;
    // }
    // this.$store.dispatch("CountryList/GetAllCountries");

    this.searchData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
<style scoped>

#doctorSetting .vs-button {
  margin-bottom: 20px !important;
}
#doctorSetting .text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
#doctorSetting h3 {
  font-weight: bold;

  font-size: 1.7rem !important;
}
</style>